import React, { useState, useEffect, useRef } from 'react';
import './App.css';
import jekaVideo from './assets/jeka.mp4';
import collisionSound from './assets/sound1.mp3';
import victorySound from './assets/sound2.mp3';
import levelCompleteSound from './assets/sound3.mp3';
import welcomeSound from './assets/welcome.mp3';
import stickerVideo from './assets/sticker.webm';

const LEVELS = {
    1: {
        grid: [
            ['S',0,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1],
            [0,0,0,0,0,1,0,0,0,0,0,0,0,0,0,1,0,0,0,1],
            [1,1,1,1,0,1,0,1,1,1,1,1,1,1,0,1,0,1,0,1],
            [1,0,0,0,0,0,0,0,0,0,0,0,0,1,0,0,0,1,0,1],
            [1,0,1,1,1,1,1,1,1,1,1,1,0,1,1,1,1,1,0,1],
            [1,0,0,0,0,0,0,1,0,0,0,1,0,0,0,0,0,0,0,1],
            [1,1,1,1,1,1,0,1,0,1,0,1,1,1,1,1,1,1,0,1],
            [1,0,0,0,0,1,0,1,0,1,0,0,0,0,0,0,0,1,0,1],
            [1,0,1,1,0,1,0,1,0,1,1,1,1,1,1,1,0,1,0,1],
            [1,0,1,0,0,1,0,1,0,0,0,0,0,0,0,1,0,0,0,1],
            [1,0,1,0,1,1,0,1,1,1,1,1,1,1,0,1,1,1,1,1],
            [1,0,1,0,0,0,0,0,0,0,0,0,0,1,0,0,0,0,0,1],
            [1,0,1,1,1,1,1,1,1,1,1,1,0,1,1,1,1,1,0,1],
            [1,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,'F'],
            [1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1]
        ]
    },
    2: {
        grid: [
            ['S',0,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1],
            [1,0,0,0,0,0,0,0,0,1,0,0,0,0,0,0,0,0,0,1],
            [1,1,1,1,1,1,1,1,0,1,0,1,1,1,1,1,1,1,0,1],
            [1,0,0,0,0,0,0,1,0,1,0,0,0,0,0,0,0,1,0,1],
            [1,0,1,1,1,1,0,1,0,1,1,1,1,1,1,1,0,1,0,1],
            [1,0,0,0,0,1,0,1,0,0,0,0,0,0,0,1,0,1,0,1],
            [1,1,1,1,0,1,0,1,1,1,1,1,1,1,0,1,0,1,0,1],
            [1,0,0,0,0,1,0,0,0,0,0,0,0,1,0,1,0,1,0,1],
            [1,0,1,1,1,1,1,1,1,1,1,1,0,1,0,1,0,1,0,1],
            [1,0,0,0,0,0,0,0,0,0,0,1,0,1,0,1,0,0,0,1],
            [1,1,1,1,1,1,1,1,1,1,0,1,0,1,0,1,1,1,0,1],
            [1,0,0,0,0,0,0,0,0,1,0,1,0,0,0,0,0,1,0,1],
            [1,0,1,1,1,1,1,1,0,1,0,1,1,1,1,1,0,1,0,1],
            [1,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,'F'],
            [1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1]
        ]
    }
};

function App() {
    const [gameState, setGameState] = useState('start');
    const [currentLevel, setCurrentLevel] = useState(1);
    const [lives, setLives] = useState(3);
    const [soundEnabled, setSoundEnabled] = useState(false);
    const [playerPosition, setPlayerPosition] = useState({ x: 0, y: 0 });
    const [isCollision, setIsCollision] = useState(false);
    const [isLevelTransition, setIsLevelTransition] = useState(false);
    const [isFirstInteraction, setIsFirstInteraction] = useState(true);

    const mazeRef = useRef(null);
    const cursorRef = useRef(null);
    const collisionAudioRef = useRef(null);
    const victoryAudioRef = useRef(null);
    const levelCompleteAudioRef = useRef(null);
    const welcomeAudioRef = useRef(null);

    const moveSpeed = 12;

    useEffect(() => {
        if (soundEnabled) {
            collisionAudioRef.current = new Audio(collisionSound);
            victoryAudioRef.current = new Audio(victorySound);
            levelCompleteAudioRef.current = new Audio(levelCompleteSound);
            welcomeAudioRef.current = new Audio(welcomeSound);
        }
    }, [soundEnabled]);

    // Воспроизведение приветственного звука при загрузке
    useEffect(() => {
        if (soundEnabled) {
            collisionAudioRef.current = new Audio(collisionSound);
            victoryAudioRef.current = new Audio(victorySound);
            levelCompleteAudioRef.current = new Audio(levelCompleteSound);
            welcomeAudioRef.current = new Audio(welcomeSound);
        }
    }, [soundEnabled]);

    useEffect(() => {
        if (gameState === 'playing') {
            const startPosition = findStartPosition();
            movePlayerToPosition(startPosition);
        }
    }, [currentLevel, gameState]);

    useEffect(() => {
        let keysPressed = new Set();

        const handleKeyDown = (e) => {
            if (!['ArrowUp', 'ArrowDown', 'ArrowLeft', 'ArrowRight', 'w', 'a', 's', 'd'].includes(e.key)) return;
            e.preventDefault();
            keysPressed.add(e.key);
        };

        const handleKeyUp = (e) => {
            keysPressed.delete(e.key);
        };

        const movePlayer = () => {
            if (gameState !== 'playing' || isCollision || isLevelTransition) return;

            let newPosition = { ...playerPosition };
            let moved = false;

            if (keysPressed.has('ArrowUp') || keysPressed.has('w')) {
                newPosition.y -= moveSpeed;
                moved = true;
            }
            if (keysPressed.has('ArrowDown') || keysPressed.has('s')) {
                newPosition.y += moveSpeed;
                moved = true;
            }
            if (keysPressed.has('ArrowLeft') || keysPressed.has('a')) {
                newPosition.x -= moveSpeed;
                moved = true;
            }
            if (keysPressed.has('ArrowRight') || keysPressed.has('d')) {
                newPosition.x += moveSpeed;
                moved = true;
            }

            if (moved && isValidPosition(newPosition)) {
                movePlayerToPosition(newPosition);
            }
        };

        const gameLoop = setInterval(movePlayer, 16); // 60 FPS

        if (gameState === 'playing') {
            window.addEventListener('keydown', handleKeyDown);
            window.addEventListener('keyup', handleKeyUp);
        }

        return () => {
            clearInterval(gameLoop);
            window.removeEventListener('keydown', handleKeyDown);
            window.removeEventListener('keyup', handleKeyUp);
        };
    }, [gameState, playerPosition, isCollision, isLevelTransition]);

    const findStartPosition = () => {
        const grid = LEVELS[currentLevel].grid;
        for (let y = 0; y < grid.length; y++) {
            for (let x = 0; x < grid[0].length; x++) {
                if (grid[y][x] === 'S') {
                    return getPixelPosition(x, y);
                }
            }
        }
        return { x: 0, y: 0 };
    };

    const getPixelPosition = (gridX, gridY) => {
        const rect = mazeRef.current?.getBoundingClientRect();
        if (!rect) return { x: 0, y: 0 };

        const cellWidth = rect.width / LEVELS[currentLevel].grid[0].length;
        const cellHeight = rect.height / LEVELS[currentLevel].grid.length;

        return {
            x: gridX * cellWidth + cellWidth / 3,
            y: gridY * cellHeight + cellHeight / 3
        };
    };

    const isValidPosition = (position) => {
        if (!mazeRef.current) return false;

        const rect = mazeRef.current.getBoundingClientRect();

        if (position.x < 0 || position.x > rect.width - 20 ||
            position.y < 0 || position.y > rect.height - 20) {
            return false;
        }

        if (checkCollision(position.x, position.y)) {
            handleCollision();
            return false;
        }

        return true;
    };

    const movePlayerToPosition = (position) => {
        setPlayerPosition(position);
        if (cursorRef.current) {
            cursorRef.current.style.transform = `translate(${position.x}px, ${position.y}px)`;
        }
    };

    const playSound = async (audioRef) => {
        if (soundEnabled && audioRef.current) {
            try {
                audioRef.current.currentTime = 0;
                await audioRef.current.play();
            } catch (error) {
                console.error('Error playing sound:', error);
            }
        }
    };

    const handleStartGame = () => {
        setSoundEnabled(true);
        setGameState('playing');
        const startPos = findStartPosition();
        movePlayerToPosition(startPos);

        // Воспроизводим welcome звук при первом взаимодействии
        if (isFirstInteraction) {
            setTimeout(() => {
                welcomeAudioRef.current?.play().catch(error => {
                    console.error('Error playing welcome sound:', error);
                });
            }, 100);
            setIsFirstInteraction(false);
        }
    };

    useEffect(() => {
        const handleFirstInteraction = () => {
            if (isFirstInteraction) {
                welcomeAudioRef.current = new Audio(welcomeSound);
                welcomeAudioRef.current.play().catch(error => {
                    console.error('Error playing welcome sound:', error);
                });
                setIsFirstInteraction(false);
                // Удаляем обработчик после первого использования
                document.removeEventListener('click', handleFirstInteraction);
            }
        };

        document.addEventListener('click', handleFirstInteraction);

        return () => {
            document.removeEventListener('click', handleFirstInteraction);
        };
    }, [isFirstInteraction]);

    const checkCollision = (x, y) => {
        if (!mazeRef.current) return false;

        const rect = mazeRef.current.getBoundingClientRect();
        const grid = LEVELS[currentLevel].grid;
        const cellWidth = rect.width / grid[0].length;
        const cellHeight = rect.height / grid.length;

        const gridX = Math.floor(x / cellWidth);
        const gridY = Math.floor(y / cellHeight);

        if (gridY >= 0 && gridY < grid.length && gridX >= 0 && gridX < grid[0].length) {
            const cell = grid[gridY][gridX];
            if (cell === 1) return true;
            if (cell === 'F' && !isLevelTransition) {
                handleLevelComplete();
                return false;
            }
        }
        return false;
    };

    const handleCollision = () => {
        if (isCollision) return;

        setIsCollision(true);
        if (lives > 1) {
            setLives(prev => prev - 1);
            playSound(collisionAudioRef);

            const startPos = findStartPosition();
            movePlayerToPosition(startPos);

            setTimeout(() => {
                setIsCollision(false);
            }, 1000);
        } else {
            resetLevel();
        }
    };

    const handleLevelComplete = () => {
        setIsLevelTransition(true);

        // Выбираем нужный звук в зависимости от уровня
        if (currentLevel === 1) {
            playSound(levelCompleteAudioRef);
        } else if (currentLevel === Object.keys(LEVELS).length) {
            playSound(victoryAudioRef);
        }

        setTimeout(() => {
            if (currentLevel < Object.keys(LEVELS).length) {
                setCurrentLevel(prev => prev + 1);
                setLives(3);
                setIsCollision(false);
                const startPos = findStartPosition();
                movePlayerToPosition(startPos);
                setTimeout(() => {
                    setIsLevelTransition(false);
                }, 100);
            } else {
                setGameState('congratulation');
            }
        }, 500);
    };
    const resetLevel = () => {
        setLives(3);
        setIsCollision(false);
        const startPos = findStartPosition();
        movePlayerToPosition(startPos);
    };

    const renderMaze = () => {
        const grid = LEVELS[currentLevel].grid;
        const cellWidth = 100 / grid[0].length;
        const cellHeight = 100 / grid.length;

        return grid.map((row, rowIndex) =>
            row.map((cell, colIndex) => {
                if (cell === 1) {
                    return (
                        <div
                            key={`${rowIndex}-${colIndex}`}
                            className="wall wall-animated"
                            style={{
                                top: `${rowIndex * cellHeight}%`,
                                left: `${colIndex * cellWidth}%`,
                                width: `${cellWidth}%`,
                                height: `${cellHeight}%`
                            }}
                        />
                    );
                }
                if (cell === 'F') {
                    return (
                        <div
                            key={`finish-${rowIndex}-${colIndex}`}
                            className="finish"
                            style={{
                                top: `${rowIndex * cellHeight + cellHeight/3}%`,
                                left: `${colIndex * cellWidth + cellWidth/3}%`
                            }}
                        />
                    );
                }
                return null;
            })
        );
    };

    return (
        <div className="app-container">
            <video
                className="background-video"
                autoPlay
                loop
                muted
                playsInline
            >
                <source src={stickerVideo} type="video/webm"/>
            </video>
            {gameState === 'start' && (
                <div className="start-screen">
                    <h1 className="neon-text">Dungeon Master Game</h1>
                    <button className="neon-button" onClick={handleStartGame}>
                        Начать игру
                    </button>
                </div>
            )}

            {gameState === 'playing' && (
                <>
                    <div className="dungeon-title">
                        Dungeon {currentLevel}
                    </div>
                    <div
                        ref={mazeRef}
                        className={`maze-container ${isLevelTransition ? 'level-transition' : ''}`}
                    >
                        <div className="lives">{lives}</div>
                        <div
                            className={`cursor ${isCollision ? 'collision' : ''}`}
                            ref={cursorRef}
                            style={{
                                transform: `translate(${playerPosition.x}px, ${playerPosition.y}px)`
                            }}
                        />
                        <div className="maze">
                            {renderMaze()}
                        </div>
                    </div>
                </>
            )}

            {gameState === 'congratulation' && (
                <div className="congratulation-screen">
                    <h1 className="neon-text">С Днем Рождения, Жека!</h1>
                    <div className="neon-text-small">
                        <p>Я не знал что тебе подарить и ночью придумал сие творение.</p>
                        <p>За два года знакомства ты мне стал близким человеком, и я рад, что это знакомство произошло.</p>
                        <p>Желаю тебе круто провести день рождения и по жизни оставаться самим собой.</p>
                        <p>С наилучшими пожеланиями, Макс.</p>
                    </div>
                    <button
                        className="neon-button"
                        onClick={() => setGameState('video')}
                    >
                        Получить подарок
                    </button>
                </div>
            )}

            {gameState === 'video' && (
                <video
                    className="centered-video"
                    autoPlay
                    controls={false}
                    loop
                    muted
                    playsInline
                >
                    <source src={jekaVideo} type="video/mp4"/>
                </video>
            )}
        </div>
    );
}

export default App;